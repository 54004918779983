import classNames from 'classnames';
import PapersResized from '../../images/PapersResized.png';

interface EtcooLandingPagePosterProps {
  showText: boolean;
}

export default function EtcooLandingPagePoster(
  props: EtcooLandingPagePosterProps,
) {
  const { showText } = props;

  return (
    <div
      className={classNames('absolute w-[14%] top-[21%] left-[42.25%]', {
        'cursor-help z-10 hover:animate-wiggle': showText,
      })}
    >
      <img src={PapersResized} alt="Poster" className="w-full" />
      <div
        className={classNames(
          'absolute left-[52%] -translate-x-1/2 top-[18%] font-gaegu font-bold text-center',
          { '-z-20': !showText },
        )}
        style={{ fontSize: '1vw' }}
      >
        <div>Public: 8,888</div>
        <div>Team: 1,112</div>
        <div>CC0</div>
        <div className="line-through">Roadmap</div>
        <div className="line-through">Discord</div>
      </div>
    </div>
  );
}
