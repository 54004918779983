import { getMintTbd } from '../../util/envUtil';
import EtcooCountdown from './EtcooCountdown';
import FakeCountdown from './FakeCountdown';
import FireClockResize from '../../images/FireClockResize.png';
import ClockResized from '../../images/ClockResized.png';

interface EtcooLandingPageClockProps {
  fireBurning: boolean;
}

export default function EtcooLandingPageClock(
  props: EtcooLandingPageClockProps,
) {
  const { fireBurning } = props;
  return (
    <div className="absolute w-[10%] top-[26.5%] left-[24%]">
      <div className="relative">
        <img
          src={fireBurning ? FireClockResize : ClockResized}
          alt="Alarm Cluck"
          className="w-full"
        />
        <div
          className="text-center absolute top-[3%] left-[16%] text-[#A3428B] font-digital select-none"
          style={{ fontSize: '2.1vw' }}
        >
          {!getMintTbd() ? <EtcooCountdown /> : <FakeCountdown />}
        </div>
      </div>
    </div>
  );
}
