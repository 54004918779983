import { Link } from 'react-router-dom';
import { ReactComponent as DiscordLogo } from '../../images/DiscordLogo.svg';
import { ReactComponent as TwitterLogo } from '../../images/TwitterLogo.svg';
import { ReactComponent as EtcLogo } from '../../images/EtcLogo.svg';
import WalletConnectButton from '../wallet/WalletConnectButton';

export default function EtcooMintTopBar() {
  return (
    <div className="flex w-full flex-row justify-between px-8 py-2">
      <Link to="/">
        <EtcLogo width={300} />
      </Link>
      <div className="float-right my-auto ml-auto flex flex-row items-center space-x-6">
        <a
          href="https://discord.gg/ZZrmxA8UTz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DiscordLogo className="h-5 w-5 fill-white" />
        </a>
        <a
          href="https://twitter.com/yozlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterLogo className="h-5 w-5 fill-white" />
        </a>
        <WalletConnectButton />
      </div>
    </div>
  );
}
