import { ChainName } from '@wagmi/core/dist/declarations/src/constants/chains';
import { chain } from 'wagmi';

/**
 * An enum representing the RPC provider the app is using for RPC calls.
 */
export enum RpcProvider {
  Alchemy = 'alchemy',
  Infura = 'infura',
}

/**
 * Gets the Ethereum chain the app is setup for.
 * @returns - An ethers.chain that corresponds to the chain in the .env.
 */
export function getChain() {
  const chainName = process.env.REACT_APP_CHAIN;
  if (!chainName) {
    throw new Error('Missing REACT_APP_CHAIN in .env');
  }

  const chainConfig = chain[chainName as ChainName];
  if (!chainConfig) {
    throw new Error(`Invalid chain ${chainName}`);
  }

  return chainConfig;
}

/**
 * Gets the Infura ID for Yoz's Infura RPC account.
 *
 * @returns - A string containing our Infura ID.
 */
export function getInfuraId() {
  const infuraId = process.env.REACT_APP_INFURA_ID;
  if (!infuraId) {
    throw new Error('Missing REACT_APP_INFURA_ID in .env');
  }

  return infuraId;
}

/**
 * Gets the Alchemy ID for Yoz's Infura RPC account.
 *
 * @returns - A string containing our Alchemy ID.
 */
export function getAlchemyId() {
  const alchemyId = process.env.REACT_APP_ALCHEMY_ID;
  if (!alchemyId) {
    throw new Error('Missing REACT_APP_ALCHEMY_ID in .env');
  }

  return alchemyId;
}

/**
 * Gets the RPC provider to use in the app.
 *
 * @returns - An RpcProvider corresponding to the RPC provider specified
 * in the .env.
 */
export function getRpcProvider() {
  const rpcProvider = process.env.REACT_APP_RPC_PROVIDER;
  if (!rpcProvider) {
    throw new Error('Missing REACT_APP_RPC_PROVIDER In .env');
  }

  if (
    rpcProvider !== RpcProvider.Alchemy &&
    rpcProvider !== RpcProvider.Infura
  ) {
    throw new Error(`Unknown RPC provider ${rpcProvider}`);
  }

  return rpcProvider as RpcProvider;
}

export function getContractAddress() {
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  if (!contractAddress) {
    throw new Error('Missing REACT_APP_CONTRACT_ADDRESS in .env');
  }

  return contractAddress;
}

/**
 * Used to gate features between prod and staging while the mint date is TBD.
 */
export function getMintTbd() {
  const appGated = process.env.REACT_APP_MINT_TBD;
  if (!appGated) {
    throw new Error('Missing REACT_APP_MINT_TBD in .env');
  }

  return appGated === 'true';
}

export function getOpenSeaUrl() {
  return process.env.REACT_APP_OPENSEA_URL;
}

export function getPixelContractAddress() {
  const contractAddress = process.env.REACT_APP_PIXEL_PIGEONS_CONTRACT_ADDRESS;
  if (!contractAddress) {
    throw new Error('Missing REACT_APP_PIXEL_PIGEONS_CONTRACT_ADDRESS in .env');
  }
  return contractAddress;
}
