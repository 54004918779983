import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import WalletButton from '../wallet/WalletButton';

interface EtcooPixelSuccessModalProps {
  open: boolean;
  onCloseModal: () => void;
}

export default function EtcooPixelSuccessModal(
  props: EtcooPixelSuccessModalProps,
) {
  const { open, onCloseModal } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-12 flex flex-col items-center justify-center">
                <Dialog.Title
                  as="h3"
                  className="text-3xl font-medium leading-6 text-etcoo-purple font-sprinkle mt-4 flex items-center justify-center"
                >
                  Pixel Pigeon Successfully Minted
                </Dialog.Title>
                <div className="flex flex-col w-full space-y-6 mt-8">
                  <span className="font-gaegu text-xl">
                    You are the proud owner of an{' '}
                    <span className="font-bold">Everythings Coo</span> Pixel
                    Pigeon. Noice.
                  </span>
                  <div className="flex items-center justify-center uppercase">
                    <WalletButton
                      onClick={() => onCloseModal()}
                      className="items-center justify-center w-32"
                    >
                      Ok, Coo
                    </WalletButton>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
