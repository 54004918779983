import { BigNumber, ethers } from 'ethers';
import {
  useAccount,
  useConnect,
  useContract,
  useContractWrite,
  useNetwork,
  useSigner,
  useWaitForTransaction,
} from 'wagmi';
import toast from 'react-hot-toast';

import WalletButton from '../wallet/WalletButton';
import { PixelPigeonMintStatus } from './PixelPigeonMintStatus';
import TransactionSucceedToast from '../mint/toast/TransactionSucceedToast';
import ErrorToast from '../mint/toast/ErrorToast';
import EtcooPixelSuccessModal from './EtcooPixelSuccessModal';
import { useState, useEffect } from 'react';
import { getChain } from '../../util/envUtil';
import PixelContractConfig from '../../util/PixelContractConfig';

const TRANSACTION_SUCCESS = 1;
const TRANSACTION_FAILURE = 0;

const appChain = getChain();

interface EtcooPixelActionsProps {
  mintStatus?: PixelPigeonMintStatus;
  onMintSuccess: () => void;
}

function getButtonLabel(isConnected: boolean) {
  if (!isConnected) {
    return 'Connect to claim';
  }

  return 'Mint Pixel Pigeon';
}

export default function EtcooPixelActions(props: EtcooPixelActionsProps) {
  const { mintStatus, onMintSuccess } = props;

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { isConnected } = useConnect();
  const { activeChain } = useNetwork();
  const { data: accountData } = useAccount();
  const { address } = accountData || {};

  const { data: signer } = useSigner();

  // TODO: Hook up contract
  const contractConfigWithSigner = {
    ...PixelContractConfig,
    signerOrProvider: signer || undefined,
  };

  const pixelContract: ethers.Contract = useContract(contractConfigWithSigner);

  const {
    data: mintTransactionResult,
    writeAsync: mintFromContract,
    isLoading: isSendingMintTransaction,
  } = useContractWrite(contractConfigWithSigner, 'claimPigeons');

  const mintTransactionHash = mintTransactionResult?.hash;
  const { isLoading: isWaitingForMint, data: mintTxnData } =
    useWaitForTransaction({
      hash: mintTransactionHash,
      confirmations: 1,
      enabled: !!PixelPigeonMintStatus.CanMint,
    });

  async function mint(minterAddress?: string) {
    if (!minterAddress) {
      throw new Error('No address for minter');
    }

    const estimate = await pixelContract.estimateGas.claimPigeons(
      minterAddress,
    );

    return await mintFromContract({
      args: minterAddress,
      overrides: {
        gasLimit: BigNumber.from(estimate.mul(2)),
      },
    });
  }

  useEffect(() => {
    if (!mintTransactionHash || !mintTxnData) {
      return;
    }

    if (isWaitingForMint) {
      return;
    }

    if (mintStatus !== PixelPigeonMintStatus.CanMint) {
      return;
    }

    if (mintTxnData.status === TRANSACTION_SUCCESS) {
      onMintSuccess();
      setShowSuccessModal(true);
      return;
    }

    if (mintTxnData.status === TRANSACTION_FAILURE) {
      toast.custom(<ErrorToast message={'Transaction failed.'} />);
      return;
    }
  }, [
    isWaitingForMint,
    mintTransactionHash,
    mintTxnData,
    onMintSuccess,
    mintStatus,
  ]);

  return (
    <div>
      <WalletButton
        disabled={
          !isConnected ||
          mintStatus !== PixelPigeonMintStatus.CanMint ||
          (isConnected && activeChain?.id !== appChain.id) ||
          isSendingMintTransaction ||
          isWaitingForMint
        }
        onClick={async () => {
          try {
            await mint(address);
            toast.custom(<TransactionSucceedToast />);
          } catch (error: any) {
            // If a user rejects, don't pop a toast.
            // Ideally, we would want to use the name of the URRE object from Wagmi,
            // But this will suffice for now since that object is coming back with "n" as the name.
            if (error.name === 'UserRejectedRequestError') {
              return;
            }

            toast.custom(<ErrorToast message={'Transaction failed.'} />);
          }
        }}
      >
        {getButtonLabel(isConnected)}
      </WalletButton>
      <EtcooPixelSuccessModal
        open={showSuccessModal}
        onCloseModal={() => setShowSuccessModal(false)}
      />
    </div>
  );
}
