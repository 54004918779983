import useCraInfo from '../../hooks/useCraInfo';
import { useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import classNames from 'classnames';

const ZEROES = '00:00:00';

/**
 * Helper function that formats time properly for a digital clock appearance.
 * @param time - A time from a date-fns duration, e.g. days, hours, etc.
 */
function formatTime(time: number) {
  if (time < 10) {
    return '0' + time;
  }

  return time;
}

/**
 * Helper function that returns a formatted string that looks like a digital clock for the countdown to mint.
 * @param duration - The duration object from date-fns that represents distance between 2 timestamps.
 */
function formatCountdown(duration: Duration) {
  const returnString =
    duration.days !== undefined &&
    duration.hours !== undefined &&
    duration.minutes !== undefined &&
    duration.seconds !== undefined
      ? `${formatTime(duration.hours + duration.days * 24)}:${formatTime(
          duration.minutes,
        )}:${formatTime(duration.seconds)}`
      : null;

  return returnString;
}

/**
 * Uses date-fns to calculate the countdown between two UNIX timestamps.
 * @param currentTime - The current time in milliseconds from UNIX epoch.
 * @param startTime - Auction start time in milliseconds from UNIX epoch.
 */
function calculateCountdown(
  currentTime: number,
  startTime: number | undefined,
) {
  if (!startTime) {
    return null;
  }

  const startTimeMs = startTime * 1000;

  // Mint has started.
  if (currentTime >= startTimeMs) {
    return ZEROES;
  }

  let duration = intervalToDuration({
    start: new Date(currentTime),
    end: new Date(startTimeMs),
  });

  return formatCountdown(duration);
}

/**
 * A component that calculates the countdown to the mint from a user's current time in milliseconds (unix timestamp)
 */
export default function EtcooCountdown() {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  const { startTime } = useCraInfo();
  useEffect(() => {
    if (!startTime) {
      return;
    }

    const timer = setInterval(() => setCurrentTime(Date.now()), 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  }, [startTime, currentTime]);

  const countdownTime = calculateCountdown(currentTime, startTime);

  return (
    <div>
      {
        <h2
          className={classNames({ 'animate-blink': countdownTime === ZEROES })}
        >
          {countdownTime}
        </h2>
      }
    </div>
  );
}
