import { BigNumber } from 'ethers';

/**
 * An interface representing a AuctionConfig struct in the contract.
 */
export interface AuctionConfig {
  startPrice: BigNumber;
  floorPrice: BigNumber;
  priceDelta: BigNumber;
  expectedStepMintRate: number;
  startTime: number;
  endTime: number;
  stepDurationInSeconds: number;
}

/**
 * A typeguard used to determine whether or not an object
 * is an AuctionConfig.
 * @param o - The object to test.
 * @returns - Whether or not o is an AuctionConfig.
 */
export function isAuctionConfig(
  auctionConfigResult: unknown,
): auctionConfigResult is AuctionConfig {
  const possibleAuctionConfig = auctionConfigResult as AuctionConfig;
  return (
    typeof auctionConfigResult === 'object' &&
    BigNumber.isBigNumber(possibleAuctionConfig.startPrice) &&
    BigNumber.isBigNumber(possibleAuctionConfig.floorPrice) &&
    BigNumber.isBigNumber(possibleAuctionConfig.priceDelta) &&
    typeof possibleAuctionConfig.expectedStepMintRate === 'number' &&
    typeof possibleAuctionConfig.startTime === 'number' &&
    typeof possibleAuctionConfig.endTime === 'number' &&
    typeof possibleAuctionConfig.stepDurationInSeconds === 'number'
  );
}
