import { Link } from 'react-router-dom';
import { getMintTbd } from '../../util/envUtil';
import FireMonitorResize from '../../images/FireMonitorResize.png';
import classNames from 'classnames';
import useCraInfo from '../../hooks/useCraInfo';
import { useEffect, useState } from 'react';
import MonitorResized from '../../images/MonitorResized.png';

interface EtcooLandingPageComputerProps {
  fireBurning: boolean;
}

export default function EtcooLandingPageComputer(
  props: EtcooLandingPageComputerProps,
) {
  const { fireBurning } = props;
  const { startTime } = useCraInfo();
  const startTimeMs = startTime ? startTime * 1000 : undefined;

  const [isMintActive, setIsMintActive] = useState(false);

  useEffect(() => {
    if (!startTimeMs) {
      return;
    }

    if (isMintActive) {
      return;
    }

    const intervalId = setInterval(() => {
      const now = Date.now();
      if (now >= startTimeMs) {
        setIsMintActive(true);
      }
    }, 1000);

    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [startTimeMs, isMintActive]);

  const isLinkActive = !getMintTbd() && fireBurning && isMintActive;

  return (
    <div className="absolute w-[12%] top-[46%] left-[44%] z-10">
      <Link
        to={isLinkActive ? '/pixel' : ''}
        className={classNames({ 'pointer-events-none': !isLinkActive })}
      >
        <img
          src={fireBurning ? FireMonitorResize : MonitorResized}
          className="w-full"
          alt="CooMputer"
        />
        <button
          className={classNames(
            'absolute left-[48%] -translate-x-1/2 top-[38%] -translate-y-1/2 rounded-lg bg-etcoo-purple text-white font-sprinkle px-[2%] py-[1%] drop-shadow-xl',
            { hidden: !isLinkActive },
          )}
          style={{ fontSize: '1.25vw' }}
        >
          Pixels?
        </button>
      </Link>
    </div>
  );
}
