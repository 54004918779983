import { PixelPigeonMintStatus } from './PixelPigeonMintStatus';

interface EtcooPixelFeedbackProps {
  mintStatus: PixelPigeonMintStatus;
  numberAvailableToMint?: number;
}

function getFeedbackString(
  mintStatus: PixelPigeonMintStatus,
  numberAvailableToMint?: number,
) {
  switch (mintStatus) {
    case PixelPigeonMintStatus.AlreadyMinted:
      return `Thanks for minting your Pixel Pigeon${
        numberAvailableToMint === 1 ? '' : 's'
      }!`;
    case PixelPigeonMintStatus.CanMint:
      return `Aw yeah, you can mint ${
        numberAvailableToMint === 1 ? 'a' : numberAvailableToMint
      } Pixel Pigeon${numberAvailableToMint === 1 ? '' : 's'}!`;
    case PixelPigeonMintStatus.NotInSnapshot:
      return "Sorry, you're not in the snapshot!";
  }
}

export default function EtcooPixelFeedback(props: EtcooPixelFeedbackProps) {
  const { mintStatus, numberAvailableToMint } = props;
  return (
    <div className="font-gaegu text-3xl">
      {getFeedbackString(mintStatus, numberAvailableToMint)}
    </div>
  );
}
