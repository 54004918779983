import { getPixelContractAddress } from './envUtil';
import PixelPigeonsAbi from '../abis/PixelPigeons.json';

const CONTRACT_ADDRESS = getPixelContractAddress();

const PixelContractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: PixelPigeonsAbi.abi,
};

export default PixelContractConfig;
