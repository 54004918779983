import { getContractAddress } from './envUtil';
import EtcAbi from '../abis/ETC.json';

const CONTRACT_ADDRESS = getContractAddress();

const ContractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: EtcAbi.abi,
};

export default ContractConfig;
