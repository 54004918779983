import { BigNumber, BigNumberish, utils as ethersUtils } from 'ethers';
import { getChain } from './envUtil';

/**
 * Formats an Ethereum address so it fits more nicely in the button.
 * It will be of the form 0xnGMi..ke
 *
 * @param address - The address to format.
 * @returns - The formatted address.
 */
export function ellipsizeEthAddress(address: string): string {
  return `${address.slice(0, 6)}..${address.slice(-4)}`;
}

export function getEtherscanTxnLink(txHash: string) {
  return `https://${getChain().name}.etherscan.io/tx/${txHash}`;
}

/**
 * Formats a BigNumberish so it can be displayed in terms of eth.
 *
 * @param value - The BigNumberish to format.
 * @returns - The formatted value.
 */
export function getFormattedEtherAmount(value: BigNumberish): string {
  return `${ethersUtils.formatEther(value)}Ξ`;
}

export function numberFromResult(value: ethersUtils.Result) {
  return BigNumber.from(value).toNumber();
}
