import { useMemo } from 'react';
import CraContractConfig from '../util/ContractConfig';
import { fetchOnceReadConfig } from '../wagmi/wagmiQueryConfig';
import { isTokenAllocation } from '../types/TokenAllocation';
import useContractReadWithType from './useContractReadWithType';
import { BigNumber } from 'ethers';
import { isAuctionConfig } from '../types/AuctionConfig';

export interface CraInfo {
  collectionSize?: number;
  mintsPerTransaction?: number;
  mintsPerWallet?: number;

  startPrice?: BigNumber;
  floorPrice?: BigNumber;
  priceDelta?: BigNumber;
  expectedStepMintRate?: number;
  startTime?: number;
  endTime?: number;
  stepDurationInSeconds?: number;

  isLoading: boolean;
}

/**
 * A hook used to fetch basic information about an auction.
 * This also helps us avoid common casting from an ethers.utils.Result into
 * a type that we need.
 */
export default function useCraInfo(): CraInfo {
  const { data: tokenAllocation, isLoading: isLoadingTokenAllocation } =
    useContractReadWithType(
      CraContractConfig,
      'tokenAllocation',
      fetchOnceReadConfig,
      (data) => {
        if (!isTokenAllocation(data)) {
          // TODO: Throw this error in getTokenAllocationFromResult?
          throw Error('Expected TokenAllocation');
        }

        return data;
      },
    );

  const { data: auctionConfig, isLoading: isLoadingAuctionConfig } =
    useContractReadWithType(
      CraContractConfig,
      'auctionConfig',
      fetchOnceReadConfig,
      (data) => {
        if (!isAuctionConfig(data)) {
          throw Error('Expected AuctionConfig');
        }

        return data;
      },
    );

  const { collectionSize, mintsPerTransaction, mintsPerWallet } =
    tokenAllocation || {};
  const {
    startPrice,
    floorPrice,
    priceDelta,
    expectedStepMintRate,
    startTime,
    endTime,
    stepDurationInSeconds,
  } = auctionConfig || {};

  // We consider everything to be loading until we can return all the data.
  const isLoading = isLoadingTokenAllocation || isLoadingAuctionConfig;

  const result = useMemo<CraInfo>(() => {
    return {
      collectionSize,
      mintsPerTransaction,
      mintsPerWallet,
      startPrice,
      floorPrice,
      priceDelta,
      expectedStepMintRate,
      startTime,
      endTime,
      stepDurationInSeconds,
      isLoading,
    };
  }, [
    collectionSize,
    mintsPerTransaction,
    mintsPerWallet,
    startPrice,
    floorPrice,
    priceDelta,
    expectedStepMintRate,
    startTime,
    endTime,
    stepDurationInSeconds,
    isLoading,
  ]);

  return result;
}
