import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import EtcooApp from './EtcooApp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import EtcooAppProvider from './providers/EtcooAppProvider';
import { Toaster } from 'react-hot-toast';

global.Buffer = global.Buffer || require('buffer').Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <EtcooAppProvider>
      <Router>
        <EtcooApp />
      </Router>
      <Toaster
        toastOptions={{
          // Set Toast Duration to 3 Seconds
          duration: 3000,
        }}
      />
    </EtcooAppProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
