import { BigNumber } from 'ethers';
import { useAccount } from 'wagmi';
import useContractReadWithType from '../../hooks/useContractReadWithType';
import PixelContractConfig from '../../util/PixelContractConfig';
import EtcooMintTopBar from '../mint/EtcooMintTopBar';
import EtcooPixelActions from './EtcooPixelActions';
import EtcooPixelFeedback from './EtcooPixelFeedback';
import { PixelPigeonMintStatus } from './PixelPigeonMintStatus';

const PIGEON_THRESHOLD = 16;

/**
 * Gets the PixelPigeonMintStatus based on the state we have
 * for a given address.
 *
 * @param numHolderPigeons - The number of ETC pigeons an address holds.
 * @param numPixelPigeonsMinted - The number of Pixel Pigeons an address has
 *                                minted already.
 * @returns - A PixelPigeonMintStatus representing what the wallet can do.
 */
function getMintStatus(
  numHolderPigeons?: number,
  numPixelPigeonsMinted?: number,
) {
  if (numHolderPigeons === undefined || numPixelPigeonsMinted === undefined) {
    // Not initialized.
    return undefined;
  }

  if (numHolderPigeons === 0) {
    // This address has no pigeons, and thus is not eligible.
    return PixelPigeonMintStatus.NotInSnapshot;
  }

  if (numPixelPigeonsMinted >= 1) {
    return PixelPigeonMintStatus.AlreadyMinted;
  }

  if (numPixelPigeonsMinted === 0) {
    return PixelPigeonMintStatus.CanMint;
  }

  return undefined;
}

/**
 * Returns the number of pixel pigeons that the wallet can mint.
 *
 * @param numHolderPigeons - The number of pigeons the wallet has, or undefined
 *                           if it hasn't been fetched yet.
 * @param numPixelPigeonsMinted - The number of pigeons the wallet has minted, or undefined
 *                           if it hasn't been fetched yet.
 *
 * @returns - The number of pixel pigeons the wallet can mint.
 */
function getNumberAvailableToMint(
  numHolderPigeons?: number,
  numPixelPigeonsMinted?: number,
) {
  if (numHolderPigeons === undefined || numPixelPigeonsMinted === undefined) {
    return undefined;
  }

  if (numHolderPigeons === 0) {
    return 0;
  }

  return numHolderPigeons > PIGEON_THRESHOLD ? 2 : 1;
}

export default function EtcooPixelClaim() {
  const { data: accountData } = useAccount();
  const { address } = accountData || {};

  const {
    data: numberPixelPigeonsMinted,
    refetch: refetchNumPixelPigeonsMinted,
  } = useContractReadWithType(
    PixelContractConfig,
    'numberMinted',
    { staleTime: 15_000, args: address, enabled: !!address },
    (data) => BigNumber.from(data).toNumber(),
  );

  const { data: numHolderPigeons } = useContractReadWithType(
    PixelContractConfig,
    'holderMap',
    { staleTime: 15_000, args: address, enabled: !!address },
    (data) => BigNumber.from(data).toNumber(),
  );

  const numberAvailableToMint = getNumberAvailableToMint(
    numHolderPigeons,
    numberPixelPigeonsMinted,
  );

  const mintStatus = getMintStatus(numHolderPigeons, numberPixelPigeonsMinted);

  return (
    <div className="h-full w-full">
      <EtcooMintTopBar />
      <div className="flex flex-col justify-center items-center h-full space-y-4">
        {mintStatus && (
          <EtcooPixelFeedback
            mintStatus={mintStatus}
            numberAvailableToMint={numberAvailableToMint}
          />
        )}
        <EtcooPixelActions
          mintStatus={mintStatus}
          onMintSuccess={async () => {
            await refetchNumPixelPigeonsMinted();
          }}
        />
      </div>
    </div>
  );
}
