import BgWithBookcase from '../../images/BgWithBookcase.png';
import BgFireWithBookcase from '../../images/BgFireWithBookcase.png';
import WindowFillResized from '../../images/WindowFillResized.png';
import WindowFillFireCovers from '../../images/WindowFillFireCovers.png';
import { useState } from 'react';
import classNames from 'classnames';
import FirstFireResize from '../../images/FirstFireResize.png';
import SecondFireResize from '../../images/SecondFireResize.png';
import ThirdFireResize from '../../images/ThirdFireResize.png';
import WindowFire1Resize from '../../images/WindowFire1Resize.png';
import WindowFire2Resize from '../../images/WindowFire2Resize.png';
import WindowFire3Resize from '../../images/WindowFire3Resize.png';
import BombShort from '../../images/BombShort.gif';
import { ReactComponent as ErrorImage } from '../../images/ErrorImage.svg';
import EtcooLandingPageOptions from './EtcooLandingPageOptions';
import EtcooLandingPageComputer from './EtcooLandingPageComputer';
import EtcooLandingPagePoster from './EtcooLandingPagePoster';
import EtcooLandingPageClock from './EtcooLandingPageClock';
import DrawnTwitterLogo from '../../images/DrawnTwitterLogo.png';
import DrawnOpenSeaLogo from '../../images/DrawnOpenSeaLogo.png';
import { getOpenSeaUrl } from '../../util/envUtil';

const openSeaUrl = getOpenSeaUrl();

export default function EtcooLandingPage() {
  const [nuked, setNuked] = useState<boolean>(false);
  const [nukeDropping, setNukeDropping] = useState<boolean>(false);
  const [fireBurning, setFireBurning] = useState<boolean>(false);
  const [endFadeout, setEndFadeout] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  function startAnimation() {
    setNuked(true);
    setNukeDropping(true);
    setTimeout(() => {
      setNukeDropping(false);
    }, 6000);
    setTimeout(() => {
      setFireBurning(true);
    }, 5500);
    setTimeout(() => {
      setEndFadeout(true);
    }, 8000);
    setTimeout(() => {
      setShowInfo(true);
    }, 5700);
  }

  return (
    <div className="h-full w-full relative">
      <div className="block sm:hidden h-full bg-etcoo-purple font-sprinkle text-white text-center flex items-center">
        <div className="mx-auto">
          <h1 className="text-6xl">whoops!</h1>
          <h2 className="text-3xl mt-5">I guess Not Everythings Coo</h2>
          <h3 className="text-xl mt-5">Please Use a Larger Screen</h3>
          <h3 className="text-xl mt-5">On mobile? flip To landScape!</h3>
          <ErrorImage className="h-50 w-50 fill-white mx-auto mt-10" />
        </div>
      </div>
      <div className="h-full w-full bg-[#8f6256]">
        <div
          className={classNames('fixed h-full w-full bg-white opacity-0', {
            'animate-fadeinandout z-10': nuked,
            hidden: endFadeout,
          })}
        ></div>
        <div className="w-full relative overflow-y-hidden overflow-x-hidden z-[5] hidden sm:block">
          <img
            src={fireBurning ? BgFireWithBookcase : BgWithBookcase}
            alt="Coo Apartment"
            className="z-[4] w-full"
          ></img>
          <a
            href="https://twitter.com/everythings_coo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={fireBurning ? DrawnTwitterLogo : ''}
              alt="Twitter Logo"
              className={
                fireBurning
                  ? 'top-[27.5%] left-[36.5%] absolute w-[4%] z-[15] hover:animate-wiggle'
                  : 'hidden'
              }
            ></img>
          </a>
          {openSeaUrl && (
            <a href={openSeaUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={fireBurning ? DrawnOpenSeaLogo : ''}
                alt="OS logo"
                className={
                  fireBurning
                    ? 'top-[26.5%] left-[58.25%] absolute w-[4.54%] z-[15] hover:animate-wiggle'
                    : 'hidden'
                }
              ></img>
            </a>
          )}
          <img
            src={fireBurning ? WindowFire1Resize : ''}
            className={
              fireBurning
                ? 'absolute w-[28%] [h-30%] animate-tinybounce2 top-[43.5%] left-[35.75%] -z-[5]'
                : 'hidden'
            }
            alt="Fire"
          />
          <img
            src={fireBurning ? WindowFire2Resize : ''}
            className={
              fireBurning
                ? 'absolute w-[28.5%] [h-30%] animate-tinybounce1 top-[41.5%] left-[35%] -z-[6]'
                : 'hidden'
            }
            alt="Fire"
          />
          <img
            src={fireBurning ? WindowFire3Resize : ''}
            className={
              fireBurning
                ? 'absolute w-[28.6%] animate-tinybounce3 top-[42.5%] left-[35%] -z-[7]'
                : 'hidden'
            }
            alt="Fire"
          />
          <img
            src={fireBurning ? FirstFireResize : ''}
            className={
              fireBurning
                ? 'absolute w-[100%] animate-tinybounce1 top-[28.5%] z-[10]'
                : 'hidden'
            }
            alt="Fire"
          />
          <img
            src={fireBurning ? SecondFireResize : ''}
            className={
              fireBurning
                ? 'absolute w-[100%] animate-tinybounce2 top-[25.5%]'
                : 'hidden'
            }
            alt="Fire"
          />
          <img
            src={fireBurning ? ThirdFireResize : ''}
            className={
              fireBurning
                ? 'absolute w-[100%] animate-tinybounce3 top-[37.5%]'
                : 'hidden'
            }
            alt="Fire"
          />
          <EtcooLandingPageClock fireBurning={fireBurning} />
          <EtcooLandingPageComputer fireBurning={fireBurning} />
          <img
            src={nuked && nukeDropping ? BombShort : ''}
            className={
              nuked
                ? 'absolute top-[28.5%] left-[50.5%] w-[20%] z-[-1]'
                : 'hidden'
            }
            alt="!"
          />
          <EtcooLandingPagePoster showText={showInfo} />
          <img
            src={WindowFillResized}
            alt="Scenery"
            className={
              fireBurning
                ? 'hidden'
                : 'absolute top-[28.25%] left-[57%] w-[7%] -z-50 bg-[#9EDACB]'
            }
          />
          <img
            src={WindowFillFireCovers}
            alt="FireWindow"
            className={
              fireBurning
                ? 'absolute top-[24%] w-[29%] left-[34.8%] -z-[10]'
                : 'hidden'
            }
          />
          <EtcooLandingPageOptions
            show={!nuked}
            onClickOption={startAnimation}
          />
        </div>
      </div>
    </div>
  );
}
