import { Routes, Route } from 'react-router-dom';
import EtcooLandingPage from './components/landing/EtcooLandingPage';
import EtcooPixelClaim from './components/pixel/EtcooPixelClaim';

function EtcooApp() {
  return (
    <Routes>
      <Route path="/" element={<EtcooLandingPage />} />
      <Route path="/pixel" element={<EtcooPixelClaim />} />
    </Routes>
  );
}

export default EtcooApp;
