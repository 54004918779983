/**
 * An interface representing a TokenAllocation struct in the contract.
 */
export interface TokenAllocation {
  collectionSize: number;
  mintsPerTransaction: number;
  mintsPerWallet: number;
}

/**
 * A typeguard used to determine whether or not an ethers.utils.Result is
 * a Token Result.
 * @param o - The result to test.
 * @returns - Whether or not o is a TokenAllocation.
 */
export function isTokenAllocation(o: unknown): o is TokenAllocation {
  const possibleTokenAllocation = o as TokenAllocation;
  return (
    typeof possibleTokenAllocation === 'object' &&
    typeof possibleTokenAllocation.collectionSize === 'number' &&
    typeof possibleTokenAllocation.mintsPerTransaction === 'number' &&
    typeof possibleTokenAllocation.mintsPerWallet === 'number'
  );
}
