import classNames from 'classnames';

interface EtcooLandingPageOptionsProps {
  show: boolean;
  onClickOption: () => void;
}

export default function EtcooLandingPageOptions(
  props: EtcooLandingPageOptionsProps,
) {
  const { onClickOption, show } = props;

  return (
    <div className={classNames('z-50', { hidden: !show })}>
      <h1
        className="rounded-lg absolute text-3xl top-[30%] left-[33%] font-sprinkle z-50 bg-white px-[2%] py-[1%] text-black leading-none select-none"
        style={{ fontSize: '1.5vw' }}
      >
        Markets are dipping... Quick! What to do?
      </h1>
      <button
        className="absolute rounded-lg bg-etcoo-purple text-white font-sprinkle px-[2%] py-[1%] left-[31.5%] top-[45%] z-50 drop-shadow-xl"
        onClick={onClickOption}
        style={{ fontSize: '1.25vw' }}
      >
        HODL, Everythings Coo
      </button>
      <button
        className="rounded-lg absolute bg-etcoo-purple text-white font-sprinkle px-[2%] py-[1%] left-[51.75%] top-[45%] z-50 drop-shadow-xl"
        onClick={onClickOption}
        style={{ fontSize: '1.25vw' }}
      >
        DCA your position
      </button>
    </div>
  );
}
