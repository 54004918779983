import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

/**
 * The tailwind classes used for a EtcooButton. Exported for now so we can
 * use it when dealing with links without changing too much.
 *
 * TODO: Make this type be a link or button.
 */
export const WalletButtonClassName =
  'inline-flex text-white px-4 py-2 font-sprinkle text-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg bg-etcoo-purple disabled:opacity-50';

interface WalletButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function WalletButton({
  className,
  ...props
}: WalletButtonProps) {
  return (
    <button
      className={classNames(WalletButtonClassName, className)}
      {...props}
    />
  );
}
